.body{
  height: 100vh;
  animation: change 10s infinite linear;
  font-family: roboto;
}

.card {
  text-transform: uppercase;
  height: 100vh;
  margin: auto;
  min-height: 512px;
  display: flex;
  flex-direction: column;
  background-color: rgba(67, 67, 67, 0.650);
}

@media screen and (max-width: 768px) {

  .title-holder{
    justify-content: center;
    padding-left: 30px;
  }
  .card {
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

@media  screen and (min-width: 375px){
  .title-holder{
    justify-content: center;
  }
}

.content {
  flex: 1 1 auto;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center;  
}

.content .title-holder {
  text-align: center;
  margin-bottom: 24px;
}

.content .title-holder h1 {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 12px;
}

.title {
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 28px;
  font-weight: 500;
}

@keyframes change {

  0%{
    background-image: url("./img/img1.jpg");
    background-size: cover;
    color: white;
  }
  33%{
    background-image: url("./img/img3.jpg");
    background-size: cover;
    color: white;
  }
  66%{
    background-image: url("./img/img4.jpg");
    background-size: cover;
    color: white;
  }
  100%{
    background-image: url("./img/img5.jpg");
    background-size: cover;
    color: white;
  }

}

@media screen and (max-width: 768px) {
  .content .title-holder {
    max-width: 80%;
  }
}
