.timer_container{
    display: flex;
    text-align: center;
    font-family: roboto;
    align-items: center;
    justify-content: center;
}

.tbb{
    text-transform: capitalize;
    font-size: 34px;
    font-weight: 800;
    line-height: 90px;
    background-color: rgba(166, 166, 166, 0.5);
    width: 250px;
    height: 90px;
    border-radius: 20px;
}

.time{
    margin-left: 10px;
    text-transform: capitalize;
    font-size: 25px;
    line-height: 40px;
    background-color: rgba(166, 166, 166, 0.5);
    width: 120px;
    height: 90px;
    border-radius: 20px;
}

@media screen and (max-width: 320px) {
    .tbb{
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        line-height: 60px !important;
    }    
}

@media screen and (max-width: 768px) {
    .timer_container{
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .time{
        margin-top: 5px;
        padding-bottom: 5px;
    }
    .tbb{
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        line-height: 90px;
    }
    .data{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.data{
    display: flex;
}